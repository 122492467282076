var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('filtro-por-tabela',{ref:"filtroPorTabela",attrs:{"propTableName":"list_comercial_campanha","propItems":_vm.headers}}),_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Campanhas")])]),_c('v-card-title',[_c('v-text-field',{staticClass:"mr-2 pa-2",attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"x-small":""},on:{"click":_vm.showFiltroPorTabela}},[_vm._v("Editar colunas")]),_c('imprimir-tabela-component',{staticStyle:{"float":"right"},attrs:{"propRef":[_vm.tabelaRef]}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{ref:"tabela",attrs:{"multi-sort":"","headers":_vm.headersFiltered,"items":_vm.items,"search":_vm.search,"loading-text":"Carregando...","loading":_vm.loading,"fixed-header":"","height":"75vh","footer-props":{
            itemsPerPageOptions:[-1]
          },"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
          var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.nome)),"propObjectId":item.id,"propToolName":"Campanhas"}})]}},{key:"item.data_inicio",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_inicio))+" ")]}},{key:"item.data_termino",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_termino))+" ")]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }